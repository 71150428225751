import { scaleMobile } from "../../scale";

function bannerInlineStyleBodyDescription(_ref) {
  var fontSize = _ref.design.fontSize,
      _ref$bodyDesign = _ref.bodyDesign,
      descriptionInheritFontSize = _ref$bodyDesign.descriptionInheritFontSize,
      descriptionFontSize = _ref$bodyDesign.descriptionFontSize,
      descriptionTextAlign = _ref.individualLayout.descriptionTextAlign,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      individualPrivacyOpen = _ref.individualPrivacyOpen,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-description",
    style: {
      lineHeight: 1.5,
      fontSize: scaleMobile(mobile, pageRequestUuid4, descriptionInheritFontSize ? +fontSize : +descriptionFontSize),
      textAlign: individualPrivacyOpen ? descriptionTextAlign : undefined
    }
  };
}

export { bannerInlineStyleBodyDescription };