import { scaleMobile } from "../../scale";

function bannerFooterInlineStyleSeparator(_ref) {
  var type = _ref.layout.type,
      design = _ref.design,
      _ref$footerDesign = _ref.footerDesign,
      borderWidth = _ref$footerDesign.borderWidth,
      borderColor = _ref$footerDesign.borderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useBorderWidth = scaleMobile(mobile, pageRequestUuid4, design.borderWidth);
  var style = {
    height: scaleMobile(mobile, pageRequestUuid4, +borderWidth),
    background: borderColor,
    position: "sticky",
    bottom: "var(--rendered-height-footer)"
  };

  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog" && useBorderWidth > 0) {
    style.borderLeft = "".concat(useBorderWidth, "px solid ").concat(design.borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-footer-separator",
    style: style
  };
}

export { bannerFooterInlineStyleSeparator };