import _extends from "@babel/runtime/helpers/extends";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { useCallback, Fragment } from "react";
import { useBannerOrBlocker, useLegalLinks } from "..";
import { FooterLink, FooterPoweredByLink } from "../../components";
import { jsxJoin } from "../../utils";

function useCommonFooterLinks() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      onClose = _ref.onClose,
      putPoweredByLinkInRow = _ref.putPoweredByLinkInRow,
      row1 = _ref.row1;

  var _useBannerOrBlocker = useBannerOrBlocker(),
      close = _useBannerOrBlocker.i18n.close,
      _useBannerOrBlocker$f = _useBannerOrBlocker.footerDesign,
      poweredByLink = _useBannerOrBlocker$f.poweredByLink,
      linkTarget = _useBannerOrBlocker$f.linkTarget,
      poweredLink = _useBannerOrBlocker.poweredLink,
      affiliate = _useBannerOrBlocker.affiliate;

  var _useLegalLinks = useLegalLinks(),
      linkPrivacyPolicy = _useLegalLinks.linkPrivacyPolicy,
      linkImprint = _useLegalLinks.linkImprint;

  var targetAttributes = linkTarget === "_blank" ? {
    target: "_blank",
    rel: "noopener"
  } : {};
  var rows = [// Row 1
  [onClose && h(FooterLink, {
    href: "#",
    onClick: onClose,
    key: "close"
  }, h("strong", null, close))].concat(_toConsumableArray(row1 || []), [linkPrivacyPolicy && h(FooterLink, _extends({
    href: linkPrivacyPolicy.url
  }, targetAttributes, {
    key: "privacyPolicy"
  }), linkPrivacyPolicy.label), linkImprint && h(FooterLink, _extends({
    href: linkImprint.url
  }, targetAttributes, {
    key: "imprint"
  }), linkImprint.label)]).filter(Boolean), // Row 2
  [] // Nothing yet
  ];

  if (!!poweredLink && poweredByLink) {
    rows[putPoweredByLinkInRow || 0].push(h(FooterPoweredByLink, {
      key: "poweredBy",
      poweredLink: poweredLink,
      affiliate: affiliate
    }));
  }

  var render = useCallback(function (rows) {
    return jsxJoin(rows.map(function (row) {
      return row.length ? h(Fragment, {
        key: row[0].key
      }, jsxJoin(row, h(Fragment, null, " \u2022 "))) : null;
    }), h("br", null));
  }, []);
  return {
    rows: rows,
    render: render
  };
}

export { useCommonFooterLinks };