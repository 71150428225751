import { scaleMobile } from "../../scale";

function commonInlineStyleBodyTeaching(_ref) {
  var _ref$bodyDesign = _ref.bodyDesign,
      teachingsInheritTextAlign = _ref$bodyDesign.teachingsInheritTextAlign,
      teachingsTextAlign = _ref$bodyDesign.teachingsTextAlign,
      teachingsInheritFontSize = _ref$bodyDesign.teachingsInheritFontSize,
      teachingsFontSize = _ref$bodyDesign.teachingsFontSize,
      teachingsInheritFontColor = _ref$bodyDesign.teachingsInheritFontColor,
      teachingsFontColor = _ref$bodyDesign.teachingsFontColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-teachings",
    style: {
      marginTop: 7,
      display: "inline-block",
      textAlign: teachingsInheritTextAlign ? undefined : teachingsTextAlign,
      fontSize: teachingsInheritFontSize ? undefined : pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, +teachingsFontSize) : +teachingsFontSize,
      color: teachingsInheritFontColor ? undefined : teachingsFontColor
    }
  };
}

export { commonInlineStyleBodyTeaching };