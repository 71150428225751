import { bannerButtonsInlineStyleCommon } from "./";

function bannerButtonsInlineStyleSave(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  var isTcf = _ref.isTcf,
      borderRadius = _ref.layout.borderRadius,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptAllFontWeight = _ref$bodyDesign.acceptAllFontWeight,
      acceptAllFontSize = _ref$bodyDesign.acceptAllFontSize,
      _ref$saveButton = _ref.saveButton,
      type = _ref$saveButton.type,
      fontSize = _ref$saveButton.fontSize,
      bg = _ref$saveButton.bg,
      textAlign = _ref$saveButton.textAlign,
      borderColor = _ref$saveButton.borderColor,
      padding = _ref$saveButton.padding,
      borderWidth = _ref$saveButton.borderWidth,
      fontColor = _ref$saveButton.fontColor,
      fontWeight = _ref$saveButton.fontWeight,
      hoverBg = _ref$saveButton.hoverBg,
      hoverFontColor = _ref$saveButton.hoverFontColor,
      hoverBorderColor = _ref$saveButton.hoverBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return bannerButtonsInlineStyleCommon({
    name: "accept-individual-save",
    order: order,
    type: type,
    borderRadius: borderRadius,
    bg: bg,
    hoverBg: hoverBg,
    fontSize: isTcf ? acceptAllFontSize : fontSize,
    textAlign: textAlign,
    fontColor: fontColor,
    fontWeight: isTcf ? acceptAllFontWeight : fontWeight,
    hoverFontColor: hoverFontColor,
    borderWidth: borderWidth,
    borderColor: borderColor,
    hoverBorderColor: hoverBorderColor,
    padding: padding,
    antiAdBlocker: antiAdBlocker,
    pageRequestUuid4: pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}

export { bannerButtonsInlineStyleSave };