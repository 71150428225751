import { scaleMobile } from "../../scale";

function bannerInlineStyleBodyContainer(_ref) {
  var type = _ref.layout.type,
      _ref$design = _ref.design,
      bg = _ref$design.bg,
      borderWidth = _ref$design.borderWidth,
      borderColor = _ref$design.borderColor,
      padding = _ref.bodyDesign.padding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useBorderWidth = scaleMobile(mobile, pageRequestUuid4, borderWidth);
  var style = {
    background: bg,
    // Top / Bottom padding is applied in leftSide and rightSide containers
    padding: scaleMobile(mobile, pageRequestUuid4, padding).map(function (p, i) {
      return "".concat([0, 2].indexOf(i) > -1 ? 0 : p, "px");
    }).join(" "),
    lineHeight: 1.4
  };

  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog" && useBorderWidth > 0) {
    style.borderLeft = "".concat(useBorderWidth, "px solid ").concat(borderColor);
    style.borderRight = style.borderLeft;
  }

  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-body-container",
    style: style
  };
}

export { bannerInlineStyleBodyContainer };