import { scaleMobile } from "../../scale";
var BUTTONS_WIDTH = 300;

function bannerInlineStylesLeftSide(_ref) {
  var type = _ref.layout.type,
      padding = _ref.bodyDesign.padding,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      individualPrivacyOpen = _ref.individualPrivacyOpen,
      activeAction = _ref.activeAction,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useType = scaleMobile(mobile, pageRequestUuid4, type, "banner");
  var usePadding = scaleMobile(mobile, pageRequestUuid4, padding);
  var style = {
    float: useType === "banner" && activeAction !== "history" ? "left" : undefined,
    width: useType === "banner" && activeAction !== "history" ? "calc(100% - ".concat(BUTTONS_WIDTH, "px)") : undefined,
    paddingRight: useType === "banner" ? 20 : undefined,
    paddingTop: +usePadding[0],
    paddingBottom: useType === "banner" || individualPrivacyOpen ? +usePadding[2] : 0
  };
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-tb-left",
    style: style
  };
}

export { BUTTONS_WIDTH, bannerInlineStylesLeftSide };