import { useBanner, useBlocker } from "../contexts";

/**
 * Use one of the available banner or blocker contexts as union typed.
 */
function useBannerOrBlocker() {
  var banner = useBanner();
  var blocker = useBlocker(); // Check for a common context property in an available context as it is never `undefined`

  return banner.groups ? banner : blocker;
}

export { useBannerOrBlocker };