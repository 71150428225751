import { useBanner, useBannerButtonCallbacks, scaleMobile, CloseIcon, BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER } from "../../..";

var BannerCloseIcon = function BannerCloseIcon() {
  var _useBanner = useBanner(),
      _useBanner$headerDesi = _useBanner.headerDesign,
      fontSize = _useBanner$headerDesi.fontSize,
      fontColor = _useBanner$headerDesi.fontColor,
      acceptEssentials = _useBanner.texts.acceptEssentials,
      mobile = _useBanner.mobile,
      activeAction = _useBanner.activeAction,
      pageRequestUuid4 = _useBanner.pageRequestUuid4,
      _useBanner$i18n = _useBanner.i18n,
      close = _useBanner$i18n.close,
      closeWithoutSaving = _useBanner$i18n.closeWithoutSaving,
      _useBanner$buttonClic = _useBanner.buttonClicked,
      buttonClicked = _useBanner$buttonClic === void 0 ? "" : _useBanner$buttonClic;

  var _useBannerButtonCallb = useBannerButtonCallbacks(),
      buttonClickedCloseIcon = _useBannerButtonCallb.buttonClickedCloseIcon,
      closeIcon = _useBannerButtonCallb.closeIcon;

  var container = document.getElementById(pageRequestUuid4);
  return h(CloseIcon, {
    width: scaleMobile(mobile, pageRequestUuid4, fontSize),
    color: fontColor,
    tooltipText: activeAction ? activeAction === "change" ? closeWithoutSaving : close : acceptEssentials,
    tooltipAlways: (container === null || container === void 0 ? void 0 : container.clientWidth) < BANNER_SCALING_WHEN_WINDOW_WIDTH_LOWER,
    framed: buttonClicked === buttonClickedCloseIcon,
    renderInContainer: container,
    onClick: closeIcon
  });
};

export { BannerCloseIcon };