import { scaleMobile } from "../..";

function commonInlineStyleGroupsCookieProperty(_ref) {
  var _ref$group = _ref.group,
      groupBorderWidth = _ref$group.groupBorderWidth,
      groupBorderColor = _ref$group.groupBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useGroupBorderWidth = pageRequestUuid4 ? scaleMobile(mobile, pageRequestUuid4, groupBorderWidth) : groupBorderWidth;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-cookie-prop",
    style: {
      borderLeft: useGroupBorderWidth > 0 ? "1px solid ".concat(groupBorderColor) : undefined,
      paddingLeft: 15
    }
  };
}

export { commonInlineStyleGroupsCookieProperty };