import { useBannerOrBlocker } from ".";

function useLegalLinks() {
  var _useBannerOrBlocker = useBannerOrBlocker(),
      _useBannerOrBlocker$l = _useBannerOrBlocker.legal,
      privacyPolicy = _useBannerOrBlocker$l.privacyPolicy,
      privacyPolicyExternalUrl = _useBannerOrBlocker$l.privacyPolicyExternalUrl,
      privacyPolicyIsExternalUrl = _useBannerOrBlocker$l.privacyPolicyIsExternalUrl,
      privacyPolicyLabel = _useBannerOrBlocker$l.privacyPolicyLabel,
      imprint = _useBannerOrBlocker$l.imprint,
      imprintExternalUrl = _useBannerOrBlocker$l.imprintExternalUrl,
      imprintIsExternalUrl = _useBannerOrBlocker$l.imprintIsExternalUrl,
      imprintLabel = _useBannerOrBlocker$l.imprintLabel,
      pageByIdUrl = _useBannerOrBlocker.pageByIdUrl;

  var pageIdToPermalink = useBannerOrBlocker().pageIdToPermalink || {};
  var linkPrivacyPolicy = privacyPolicy > 0 ? pageIdToPermalink[privacyPolicy] || "".concat(pageByIdUrl, "=").concat(privacyPolicy) : undefined;
  var linkImprint = imprint > 0 ? pageIdToPermalink[imprint] || "".concat(pageByIdUrl, "=").concat(imprint) : undefined;

  if (privacyPolicyIsExternalUrl) {
    linkPrivacyPolicy = privacyPolicyExternalUrl;
  }

  if (imprintIsExternalUrl) {
    linkImprint = imprintExternalUrl;
  }

  return {
    linkPrivacyPolicy: privacyPolicyLabel && linkPrivacyPolicy ? {
      url: linkPrivacyPolicy,
      label: privacyPolicyLabel
    } : false,
    linkImprint: imprintLabel && linkImprint ? {
      url: linkImprint,
      label: imprintLabel
    } : false
  };
}

export { useLegalLinks };