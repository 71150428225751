import { useCallback } from "react";
import { useBanner, Cookie } from "../../..";

var BannerCookie = function BannerCookie(_ref) {
  var _consent$groups;

  var group = _ref.group,
      cookie = _ref.cookie;
  var id = cookie.id;
  var banner = useBanner();
  var consent = banner.consent,
      activeAction = banner.activeAction,
      essentialGroup = banner.essentialGroup;
  var isEssentialGroup = essentialGroup === group.slug;
  var isDisabled = isEssentialGroup || activeAction === "history";
  var isChecked = isEssentialGroup || (((_consent$groups = consent.groups) === null || _consent$groups === void 0 ? void 0 : _consent$groups[group.id]) || []).some(function (c) {
    return c === id;
  });
  var handleCheckbox = useCallback(function () {
    return !isDisabled && banner.updateCookieChecked(group.id, id, !isChecked);
  }, [isDisabled, banner, group, id, isChecked]);
  return h(Cookie, {
    cookie: cookie,
    propertyListProps: {
      isEssentialGroup: isEssentialGroup
    },
    checked: isChecked,
    disabled: isDisabled,
    onToggleCheck: handleCheckbox
  });
};

export { BannerCookie };