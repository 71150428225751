import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useLayoutEffect, useState } from "react";
import { useBannerOrBlocker } from "../../../hooks";
import { commonInlineStyleGroupsCookie, commonInlineStyleGroupsCheckbox } from "../../..";
import { PlainAntdIconAsSvg } from "../.."; // eslint-disable-next-line import/no-extraneous-dependencies

import { CheckOutlined } from "@ant-design/icons-svg";
import { CookiePropertyList } from ".";

var Cookie = function Cookie(_ref) {
  var cookie = _ref.cookie,
      checked = _ref.checked,
      disabled = _ref.disabled,
      onToggleCheck = _ref.onToggleCheck,
      _ref$propertyListProp = _ref.propertyListProps,
      propertyListProps = _ref$propertyListProp === void 0 ? {} : _ref$propertyListProp;
  var name = cookie.name;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var bannerOrBlocker = useBannerOrBlocker();
  var descriptionFontSize = bannerOrBlocker.group.descriptionFontSize;
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  return h("div", commonInlineStyleGroupsCookie(bannerOrBlocker), h("div", {
    style: {
      marginBottom: 10
    }
  }, h(PlainAntdIconAsSvg, _extends({
    icon: CheckOutlined,
    onClick: onToggleCheck
  }, commonInlineStyleGroupsCheckbox(bannerOrBlocker, isRendered, checked, disabled, descriptionFontSize))), h("strong", {
    style: {
      verticalAlign: "middle"
    },
    onClick: onToggleCheck
  }, name)), h(CookiePropertyList, _extends({
    cookie: cookie
  }, propertyListProps)));
};

export { Cookie };