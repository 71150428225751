import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { scaleMobile } from "../..";

function bannerHeaderInlineStyle(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      bannerMaxWidth = _ref$layout.bannerMaxWidth,
      textAlign = _ref.design.textAlign,
      _ref$headerDesign = _ref.headerDesign,
      inheritTextAlign = _ref$headerDesign.inheritTextAlign,
      logo = _ref$headerDesign.logo,
      logoPosition = _ref$headerDesign.logoPosition,
      restHeaderDesign = _objectWithoutProperties(_ref$headerDesign, ["inheritTextAlign", "logo", "logoPosition"]),
      headline = _ref.texts.headline,
      individualLayout = _ref.individualLayout,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4,
      individualPrivacyOpen = _ref.individualPrivacyOpen;

  var useTextAlign = inheritTextAlign ? textAlign : restHeaderDesign.textAlign;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-header",
    style: {
      transition: "width 500ms, max-width 500ms",
      maxWidth: scaleMobile(mobile, pageRequestUuid4, type, "banner") === "banner" ? +(individualPrivacyOpen && !individualLayout.inheritBannerMaxWidth ? individualLayout.bannerMaxWidth : bannerMaxWidth) : undefined,
      margin: "auto",
      display: "flex",
      justifyContent: useTextAlign === "center" ? "center" : useTextAlign === "right" ? "flex-end" : undefined,
      textAlign: useTextAlign,
      alignItems: "center",
      position: "relative",
      flexDirection: logo && headline ? logoPosition === "left" ? "row" : logoPosition === "right" ? "row-reverse" : "column" : undefined
    }
  };
}

export { bannerHeaderInlineStyle };