import { CookieConsentManager } from "@devowl-wp/cookie-consent-web-client";
import { getCmpVersion, getOtherOptionsFromWindow } from "../utils";

function getCookieConsentManager() {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      userConsentCookieName = _getOtherOptionsFromW.userConsentCookieName,
      revisionHash = _getOtherOptionsFromW.revisionHash,
      setCookiesViaManager = _getOtherOptionsFromW.setCookiesViaManager,
      groups = _getOtherOptionsFromW.groups,
      essentialGroup = _getOtherOptionsFromW.essentialGroup;

  if (!window.rcbConsentManager) {
    window.rcbConsentManager = new CookieConsentManager({
      decisionCookieName: userConsentCookieName,
      essentialGroupSlug: essentialGroup,
      groups: groups,
      revisionHash: revisionHash,
      supportsCookiesName: "real_cookie_banner-test",
      tagManager: setCookiesViaManager,
      skipOptIn: function skipOptIn(cookie) {
        var _ref = cookie,
            presetId = _ref.presetId;
        return ["amazon-associates-widget"].indexOf(presetId) > -1;
      },
      cmpId: process.env.TCF_CMP_ID,
      cmpVersion: getCmpVersion("major"),
      tcfCookieName: "".concat(userConsentCookieName, "-tcf")
    });
  }

  return window.rcbConsentManager;
}

export { getCookieConsentManager };