import Url from "url-parse";
import { trailingslashit, untrailingslashit } from "../../helpers";
import { RouteHttpVerb } from ".";
import deepMerge from "deepmerge";

/**
 * Build an URL for a specific scheme.
 *
 * @param param0
 */
function commonUrlBuilder(_ref) {
  var location = _ref.location,
      _ref$params = _ref.params,
      params = _ref$params === void 0 ? {} : _ref$params,
      _ref$nonce = _ref.nonce,
      nonce = _ref$nonce === void 0 ? true : _ref$nonce,
      options = _ref.options;
  var apiUrl = new Url(options.restRoot, true);
  var query = apiUrl.query;
  var permalinkPath = query.rest_route || apiUrl.pathname; // Determine path from permalink settings
  // Find dynamic parameters from URL bindings (like /user/:id)

  var foundParams = [];
  var path = location.path.replace(/:([A-Za-z0-9-_]+)/g, function (match, group) {
    foundParams.push(group);
    return params[group];
  });
  var getParams = {}; // Find undeclared body params (which are not bind above) and add it to GET query

  for (var _i = 0, _Object$keys = Object.keys(params); _i < _Object$keys.length; _i++) {
    var checkParam = _Object$keys[_i];

    if (foundParams.indexOf(checkParam) === -1) {
      getParams[checkParam] = params[checkParam]; // We do not need `encodeURIComponent` as it is supported by `url-parse` already
    }
  }

  var usePath = trailingslashit(permalinkPath) + untrailingslashit(location.namespace || options.restNamespace) + path; // Force protocol from parent location

  var useThisProtocol = new Url(window.location.href).protocol.slice(0, -1);
  apiUrl.set("protocol", useThisProtocol); // Set path depending on permalink settings

  if (query.rest_route) {
    query.rest_route = usePath; // eslint-disable-line @typescript-eslint/naming-convention
  } else {
    apiUrl.set("pathname", usePath); // Set path
  } // Append others


  if (nonce && options.restNonce) {
    query._wpnonce = options.restNonce;
  }

  var currentApiUrlString = apiUrl.toString();

  if (["wp-json/", "rest_route="].filter(function (s) {
    return currentApiUrlString.indexOf(s) > -1;
  }).length > 0 && location.method && location.method !== RouteHttpVerb.GET) {
    query._method = location.method;
  }

  return apiUrl.set("query", deepMerge.all([options.restQuery, getParams, query])).toString();
}

export { commonUrlBuilder, Url };