import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { bannerContentInlineStyleInner, useBanner, useBannerAnimation, useResettableAnimation } from "../../..";
import { AnimatedCss } from "../..";
import { BannerContent } from ".";

var BannerAnimatedContent = function BannerAnimatedContent() {
  var banner = useBanner();
  var _banner$layout = banner.layout,
      animationInDuration = _banner$layout.animationInDuration,
      animationOutDuration = _banner$layout.animationOutDuration,
      animationVisible = banner.animationVisible;

  var _useBannerAnimation = useBannerAnimation()(banner.layout),
      useAnimationIn = _useBannerAnimation.useAnimationIn,
      useAnimationOut = _useBannerAnimation.useAnimationOut;

  var _useResettableAnimati = useResettableAnimation(useAnimationIn, animationInDuration),
      _useResettableAnimati2 = _slicedToArray(_useResettableAnimati, 2),
      stateAnimationIn = _useResettableAnimati2[0],
      stateAnimationInDuration = _useResettableAnimati2[1];

  var _useResettableAnimati3 = useResettableAnimation(useAnimationOut === "none" ? "fadeOut" : useAnimationOut, useAnimationOut === "none" ? 0 : animationOutDuration),
      _useResettableAnimati4 = _slicedToArray(_useResettableAnimati3, 2),
      stateAnimationOut = _useResettableAnimati4[0],
      stateAnimationOutDuration = _useResettableAnimati4[1];

  return h(AnimatedCss, _extends({
    animationIn: stateAnimationIn,
    animationInDuration: stateAnimationInDuration,
    animationOut: stateAnimationOut,
    animationOutDuration: stateAnimationOutDuration,
    isVisible: animationVisible
  }, bannerContentInlineStyleInner(banner)), h(BannerContent, null));
};

export { BannerAnimatedContent };