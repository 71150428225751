import { useMemo } from "react";
import { prepareTcfString } from "../tcf";
import { applyTcfModelMetadata, applyRestrictivePurposes, createTcfModel, createTcfModelFromCookie } from "@devowl-wp/cookie-consent-web-client";
import { getCookieConsentManager } from "../others";
/**
 * Merges the localized `tcf` and `tcfMetadata` together so it can be
 * used inside our `BannerContextProps` and returns a valid `GVL` instance.
 */

function useTcf(active, tcf, tcfMeta) {
  return useMemo(function () {
    if (process.env.IS_TCF === "1" && active && Object.values(tcf.vendors).length > 0) {
      var gvl;
      var model;
      var manager = getCookieConsentManager();
      var restored = createTcfModelFromCookie(tcf, tcfMeta, manager.getOption("tcfCookieName")); // First, try to resolve from user consent (cookie value)

      if (restored) {
        gvl = restored.gvl;
        model = restored.model;
      } else {
        // No consent given, let's create an empty model
        var newTcf = createTcfModel({
          tcf: tcf,
          tcfMeta: tcfMeta,
          tcfString: ""
        });
        gvl = newTcf.gvl;
        model = newTcf.model;
      }

      applyTcfModelMetadata(model, tcfMeta, manager);
      applyRestrictivePurposes(model, tcf.vendorConfigurations);

      if (!restored) {
        prepareTcfString(model, "initial");
      }

      return {
        gvl: gvl,
        model: model,
        original: tcf,
        metadata: tcfMeta
      };
    } else {
      return undefined;
    }
  }, [active, tcf, tcfMeta]);
}

export { useTcf };