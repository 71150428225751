var untrailingslashit = function untrailingslashit(str) {
  return str.endsWith("/") || str.endsWith("\\") ? untrailingslashit(str.slice(0, -1)) : str;
};

var trailingslashit = function trailingslashit(str) {
  return "".concat(untrailingslashit(str), "/");
}; // Allows to make an interface extension and make some properties optional (https://git.io/JeK6J)


/**
 * You need to manually put the following code snippet in each of your entry points to enable
 * "on the fly" public path for chunks:
 *
 * ```
 * __webpack_public_path__ = getWebpackPublicPath(process.env);
 * ```
 *
 * @see https://webpack.js.org/guides/public-path/#on-the-fly
 */
var getWebpackPublicPath = function getWebpackPublicPath(env) {
  var options = window[env.slug.replace(/-([a-z])/g, function (g) {
    return g[1].toUpperCase();
  })];
  return "".concat(options.publicUrl).concat(options.chunkFolder, "/");
};

export { untrailingslashit, trailingslashit, getWebpackPublicPath };