import { scaleMobile } from "../..";

function bannerOverlayInlineStyleAlignment(_ref) {
  var _ref$layout = _ref.layout,
      type = _ref$layout.type,
      dialogPosition = _ref$layout.dialogPosition,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var style = {
    display: "flex",
    width: "100%",
    height: "100%"
  };

  if (scaleMobile(mobile, pageRequestUuid4, type, "banner") === "dialog") {
    style.alignItems = dialogPosition.startsWith("top") ? "flex-start" : dialogPosition.startsWith("bottom") ? "flex-end" : "center";
    style.justifyContent = dialogPosition.endsWith("Center") ? "center" : dialogPosition.endsWith("Left") ? "flex-start" : "flex-end";
  }

  return {
    className: "wp-exclude-emoji ".concat(antiAdBlocker === "y" ? "" : "rcb-align"),
    style: style
  };
}

export { bannerOverlayInlineStyleAlignment };