import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import { CookieConsentManager } from "../apply";
import { BANNER_PRE_DECISION_SHOW_EVENT, OPT_IN_ALL_EVENT } from "../events";
import { getUserDecision } from ".";
/**
 * Listen to opt-in so we can safely print the UUID of the current consent to HTML elements.
 */

function printConsentUuidsToHTMLElement(selector, decisionCookieName) {
  var elements = Array.prototype.slice.call(document.querySelectorAll(selector));
  document.addEventListener(BANNER_PRE_DECISION_SHOW_EVENT, function () {
    elements.forEach(function (element) {
      return element.innerHTML = element.getAttribute("data-fallback");
    });
  });
  document.addEventListener(OPT_IN_ALL_EVENT, function () {
    var consent = getUserDecision(decisionCookieName instanceof CookieConsentManager ? decisionCookieName.getOption("decisionCookieName") : decisionCookieName);
    var allUuids = consent ? [consent.uuid].concat(_toConsumableArray(consent.previousUuids)) : [];
    elements.forEach(function (element) {
      return element.innerHTML = allUuids.length > 0 ? allUuids.join(", ") : element.getAttribute("data-fallback");
    });
  });
}

export { printConsentUuidsToHTMLElement };