function bannerInlineStyleHistorySelectOption(_ref) {
  var bg = _ref.design.bg;
  return {
    className: undefined,
    style: {
      background: bg
    }
  };
}

export { bannerInlineStyleHistorySelectOption };