import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { scaleMobile, useBanner, usePlainCss, useRenderedHeightsAsCssVars, bannerContentInlineStyle, bannerFooterInlineStyleSeparator, bannerHeaderInlineStyleSeparator, BannerHeader, BannerFooter, BannerBody } from "../../..";
import { Fragment, useState, useRef, useLayoutEffect, useCallback } from "react";

var BannerContent = function BannerContent() {
  var banner = useBanner();
  var _banner$layout = banner.layout,
      type = _banner$layout.type,
      dialogBorderRadius = _banner$layout.dialogBorderRadius,
      _banner$decision = banner.decision,
      acceptAll = _banner$decision.acceptAll,
      acceptEssentials = _banner$decision.acceptEssentials,
      showCloseIcon = _banner$decision.showCloseIcon,
      mobile = banner.mobile,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      bg = banner.design.bg,
      _banner$bodyDesign = banner.bodyDesign,
      teachingsFontColor = _banner$bodyDesign.teachingsFontColor,
      acceptEssentialsUseAcceptAll = _banner$bodyDesign.acceptEssentialsUseAcceptAll,
      activeAction = banner.activeAction,
      pageRequestUuid4 = banner.pageRequestUuid4;
  var rightSideId = "bnnr-body-rightSide-".concat(pageRequestUuid4);
  var id = "cntnt-".concat(pageRequestUuid4);
  var ref = useRef();
  var currentRef = ref.current;
  var useAcceptEssentials = acceptEssentialsUseAcceptAll ? acceptAll : acceptEssentials;
  var showHeader = true;

  if (mobile.hideHeader && !activeAction && !individualPrivacyOpen && (useAcceptEssentials === "hide" ? !showCloseIcon : true)) {
    showHeader = scaleMobile(mobile, pageRequestUuid4, showHeader, false);
  } // Enable box shadow effect for the scrolling behavior


  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showScrollBoxShadow = _useState2[0],
      setShowScrollBoxShadow = _useState2[1];

  var updateShowScrollBoxShadow = useCallback(function () {
    if (currentRef) {
      var clientHeight = currentRef.clientHeight,
          scrollHeight = currentRef.scrollHeight,
          scrollTop = currentRef.scrollTop,
          offsetHeight = currentRef.offsetHeight;
      var hasScrollbar = clientHeight < scrollHeight;
      var scrolledToBottom = Math.ceil(scrollTop + offsetHeight) >= scrollHeight;
      var result = hasScrollbar && !scrolledToBottom && !individualPrivacyOpen;

      if (showScrollBoxShadow !== result) {
        setShowScrollBoxShadow(result);
      }
    }
  }, [currentRef, individualPrivacyOpen, showScrollBoxShadow]);
  updateShowScrollBoxShadow(); // The function gets called for each resize in `Banner` component

  useLayoutEffect(function () {
    if (currentRef) {
      currentRef.addEventListener("scroll", updateShowScrollBoxShadow);
      return function () {
        return currentRef.removeEventListener("scroll", updateShowScrollBoxShadow);
      };
    } // eslint-disable-next-line @typescript-eslint/no-empty-function


    return function () {};
  }, [currentRef, updateShowScrollBoxShadow]);
  usePlainCss(showScrollBoxShadow ? "#".concat(rightSideId, " {box-shadow:var(--boxShadowShowScroll);}") : ""); // Create custom scrollbar when a border radius is active (https://css-tricks.com/the-current-state-of-styling-scrollbars/)

  usePlainCss(navigator.platform === "Win32" && type === "dialog" && dialogBorderRadius > 0 ? ["#".concat(id, "{overflow:overlay!important;}"), "#".concat(id, "::-webkit-scrollbar{width:11px;}"), "#".concat(id, "{scrollbar-width:thin;scrollbar-color:").concat(teachingsFontColor, " transparent;}"), "#".concat(id, "::-webkit-scrollbar-track{background:transparent;}"), "#".concat(id, "::-webkit-scrollbar-thumb{background-color:").concat(teachingsFontColor, ";border-radius:").concat(dialogBorderRadius, "px;border:3px solid ").concat(bg, ";}"), "#".concat(id, ">div{border-top-right-radius:0!important;border-bottom-right-radius:0!important;}")].join("\n") : "");
  var headerRef = useRef();
  var footerRef = useRef();
  var rightSideContainerRef = useRef();
  useRenderedHeightsAsCssVars(headerRef.current, "header");
  useRenderedHeightsAsCssVars(footerRef.current, "footer");
  useRenderedHeightsAsCssVars(rightSideContainerRef.current, "rightSideContainer");
  return h("div", _extends({}, bannerContentInlineStyle(banner), {
    ref: ref,
    id: id
  }), showHeader && h(Fragment, null, h(BannerHeader, {
    ref: headerRef
  }), h("div", bannerHeaderInlineStyleSeparator(banner))), h(BannerBody, {
    rightSideContainerRef: rightSideContainerRef
  }), h("div", bannerFooterInlineStyleSeparator(banner)), h(BannerFooter, {
    ref: footerRef
  }));
};

export { BannerContent };