import { pregQuote } from ".";
/**
 * Create regular expression pattern from a string like `my_test*` and replace asterisks
 * with `(.*)`.
 */

function regExpPatternFromWildcardName(name) {
  var tmp = name.replace(/\*/g, "PLEACE_REPLACE_ME_AGAIN");
  return "^".concat(pregQuote(tmp).replace(/PLEACE_REPLACE_ME_AGAIN/g, "(.*)"), "$");
}

export { regExpPatternFromWildcardName };