import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useLayoutEffect, Fragment, useCallback } from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { CheckOutlined, MinusOutlined } from "@ant-design/icons-svg";
import { useBanner, bannerGroupsInlineStyleGroup, commonInlineStyleGroupsCheckbox, commonGroupsInlineStyleLinkMore, PlainAntdIconAsSvg, BannerGroupSectionTitle, BannerCookie } from "../../..";

var BannerGroup = function BannerGroup(_ref) {
  var _consent$groups$id;

  var group = _ref.group;
  var banner = useBanner();
  var id = group.id,
      slug = group.slug,
      name = group.name,
      description = group.description,
      items = group.items;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      isHover = _useState4[0],
      setIsHover = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      isOpen = _useState6[0],
      setIsOpen = _useState6[1];

  var previewCheckboxActiveState = banner.previewCheckboxActiveState,
      _banner$individualTex = banner.individualTexts,
      showMore = _banner$individualTex.showMore,
      hideMore = _banner$individualTex.hideMore,
      consent = banner.consent,
      activeAction = banner.activeAction,
      essentialGroup = banner.essentialGroup;
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  var isEssentialGroup = essentialGroup === slug;
  var isDisabled = isEssentialGroup || activeAction === "history";
  var isChecked = previewCheckboxActiveState || isEssentialGroup || !!consent.groups[id];
  var isPartial = !isEssentialGroup && JSON.stringify(items.map(function (_ref2) {
    var id = _ref2.id;
    return id;
  }).sort()) !== JSON.stringify(((_consent$groups$id = consent.groups[id]) === null || _consent$groups$id === void 0 ? void 0 : _consent$groups$id.sort()) || []);
  var CheckboxIcon = isPartial ? MinusOutlined : CheckOutlined;
  var handleCheckbox = useCallback(function () {
    return !isDisabled && banner.updateGroupChecked(id, !isChecked);
  }, [isDisabled, banner, id, isChecked]);
  return h("div", bannerGroupsInlineStyleGroup(banner), h(BannerGroupSectionTitle, {
    title: h(Fragment, null, h(PlainAntdIconAsSvg, _extends({
      icon: CheckboxIcon,
      onClick: handleCheckbox
    }, commonInlineStyleGroupsCheckbox(banner, isRendered, isChecked, isDisabled))), h("span", {
      style: {
        verticalAlign: "middle",
        cursor: "pointer"
      },
      onClick: handleCheckbox
    }, name, " (", items.length, ")"))
  }, description, !!items && h(Fragment, null, "\xA0\xA0\u2022\xA0\xA0", h("a", _extends({
    href: "#"
  }, commonGroupsInlineStyleLinkMore(banner, isHover), {
    onClick: function onClick(e) {
      setIsOpen(!isOpen);
      e.preventDefault();
    },
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }), isOpen ? hideMore : showMore), isOpen && items.map(function (cookie) {
    return h(BannerCookie, {
      key: cookie.id,
      group: group,
      cookie: cookie
    });
  }))));
};

export { BannerGroup };