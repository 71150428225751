import { scaleMobile, useBanner, bannerGroupsInlineStyle, BannerGroup } from "../../..";
import { Fragment } from "react";

var BannerGroupList = function BannerGroupList() {
  var banner = useBanner();
  var groups = banner.groups,
      groupSpacing = banner.group.groupSpacing,
      mobile = banner.mobile,
      pageRequestUuid4 = banner.pageRequestUuid4;
  var useGroups = groups.filter(function (_ref) {
    var items = _ref.items;
    return items.length;
  });
  return h("div", bannerGroupsInlineStyle(banner), useGroups.map(function (group, index) {
    return h(Fragment, {
      key: group.id
    }, h(BannerGroup, {
      key: group.id,
      group: group
    }), index !== useGroups.length - 1 && h("div", {
      style: {
        height: scaleMobile(mobile, pageRequestUuid4, +groupSpacing)
      }
    }));
  }));
};

export { BannerGroupList };