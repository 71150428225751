function commonGroupsInlineStyleLinkMore(_ref, isHover) {
  var linkTextDecoration = _ref.design.linkTextDecoration,
      _ref$group = _ref.group,
      linkColor = _ref$group.linkColor,
      linkHoverColor = _ref$group.linkHoverColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker;
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group-more",
    style: {
      color: isHover ? linkHoverColor : linkColor,
      textDecoration: linkTextDecoration
    }
  };
}

export { commonGroupsInlineStyleLinkMore };