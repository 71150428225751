import { tcfCmpApiDispatcher } from "@devowl-wp/cookie-consent-web-client";
import { CmpApi } from "@iabtcf/cmpapi";
import { getCookieConsentManager } from "../others";
import { getCmpVersion, getOtherOptionsFromWindow } from "../utils";
var cmpApiInstance;
/**
 * Initialize a CMP API once for this page request. If TCF is not available, it will
 * return `undefined`.
 *
 * @see https://www.npmjs.com/package/@iabtcf/cmpapi
 */

function tcfCmpApi() {
  var other = getOtherOptionsFromWindow();
  var isTcf = other.isTcf;

  if (process.env.IS_TCF === "1" && isTcf) {
    var tcf = other.tcf,
        tcfMetadata = other.tcfMetadata;
    var scope = tcfMetadata.scope; // Create instance only once

    if (!cmpApiInstance) {
      cmpApiInstance = new CmpApi(+process.env.TCF_CMP_ID, // Currently, only two bits (https://git.io/JmuD0) can be saved to the cmpVersion, so we can only save the major version
      getCmpVersion("major"), scope === "service-specific"); // Dispatch events to vendors can listen to it

      setTimeout(function () {
        return tcfCmpApiDispatcher(tcf, tcfMetadata, cmpApiInstance, getCookieConsentManager().getOption("tcfCookieName"));
      }, 0);
    }

    return cmpApiInstance;
  } else {
    return undefined;
  }
}

export { tcfCmpApi };