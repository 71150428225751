import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { createTcfModel } from "@devowl-wp/cookie-consent-web-client";

var cancelEvent = function cancelEvent(e) {
  if (!e) {
    return;
  }

  e.preventDefault(); // Some themes (e.g. AKEA) do have listeners on each link separately and do a custom redirect

  e.stopPropagation();
};

function useBannerStateContextCallbacks(_ref) {
  var toggleOverlay = _ref.toggleOverlay,
      _revokeConsent = _ref.revokeConsent;

  var toggleWithActiveAction = function toggleWithActiveAction(setContextValue, type) {
    return setContextValue(function (state) {
      return _objectSpread(_objectSpread({}, state), {}, {
        activeAction: type,
        individualPrivacyOpen: true,
        refreshSiteAfterSave: type === "change" ? 2000 : false
      }, toggleOverlay(state, true));
    });
  };

  return {
    openHistory: function openHistory(setContextValue) {
      toggleWithActiveAction(setContextValue, "history");
      cancelEvent(arguments.length <= 1 ? undefined : arguments[1]);
    },
    openBanner: function openBanner(setContextValue) {
      toggleWithActiveAction(setContextValue, "change");
      cancelEvent(arguments.length <= 1 ? undefined : arguments[1]);
    },
    revokeConsent: function revokeConsent(setContextValue) {
      for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        args[_key - 1] = arguments[_key];
      }

      var successMessage = args[0],
          e = args[1];
      setContextValue(function (state) {
        _revokeConsent(state).then(function () {
          successMessage && alert(successMessage); // Always reload (always wait 2s to ensure opt-out scripts are exeucted)
          // Fix iOS / Safari bug when we want to reload the page after a POST request

          setTimeout(function () {
            return window.location.reload();
          }, 2000);
        }); // Do not change anything


        return state;
      });
      cancelEvent(e);
    },
    updateTcfFilterBy: function updateTcfFilterBy(setContextValue) {
      for (var _len2 = arguments.length, args = new Array(_len2 > 1 ? _len2 - 1 : 0), _key2 = 1; _key2 < _len2; _key2++) {
        args[_key2 - 1] = arguments[_key2];
      }

      var newFilter = args[0];
      setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          tcfFilterBy: newFilter
        });
      });
    },
    updateIndividualPrivacyOpen: function updateIndividualPrivacyOpen(setContextValue) {
      for (var _len3 = arguments.length, args = new Array(_len3 > 1 ? _len3 - 1 : 0), _key3 = 1; _key3 < _len3; _key3++) {
        args[_key3 - 1] = arguments[_key3];
      }

      var opened = args[0];
      setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          individualPrivacyOpen: opened
        });
      });
    },
    updateGroupChecked: function updateGroupChecked(setContextValue) {
      for (var _len4 = arguments.length, args = new Array(_len4 > 1 ? _len4 - 1 : 0), _key4 = 1; _key4 < _len4; _key4++) {
        args[_key4 - 1] = arguments[_key4];
      }

      var id = args[0],
          checked = args[1];
      setContextValue(function (state) {
        var newConsent = JSON.parse(JSON.stringify(state.consent));

        if (checked) {
          newConsent.groups[id] = state.groups.filter(function (group) {
            return group.id === id;
          })[0].items.map(function (_ref2) {
            var id = _ref2.id;
            return id;
          });
        } else {
          delete newConsent.groups[id];
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          consent: newConsent,
          didGroupFirstChange: true
        });
      });
    },
    updateCookieChecked: function updateCookieChecked(setContextValue) {
      for (var _len5 = arguments.length, args = new Array(_len5 > 1 ? _len5 - 1 : 0), _key5 = 1; _key5 < _len5; _key5++) {
        args[_key5 - 1] = arguments[_key5];
      }

      var groupId = args[0],
          id = args[1],
          checked = args[2];
      setContextValue(function (state) {
        var newConsent = JSON.parse(JSON.stringify(state.consent)); // Initially create "selected" cookies

        if (!newConsent.groups[groupId]) {
          newConsent.groups[groupId] = [];
        }

        var cookieIds = newConsent.groups[groupId];
        var indexOf = cookieIds.indexOf(id);

        if (checked && indexOf === -1) {
          cookieIds.push(id);
        } else if (!checked && indexOf > -1) {
          cookieIds.splice(indexOf, 1);
        } // Delete group entirely when no cookies selected


        if (!cookieIds.length) {
          delete newConsent.groups[groupId];
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          consent: newConsent
        });
      });
    },
    onClose: function onClose(setContextValue) {
      setContextValue(function (state) {
        return _objectSpread(_objectSpread(_objectSpread({}, state), toggleOverlay(state, false)), {}, {
          // This should be set always by you again as it is one-time
          refreshSiteAfterSave: false
        });
      });
    },
    selectHistoryEntry: function selectHistoryEntry(setContextValue) {
      for (var _len6 = arguments.length, args = new Array(_len6 > 1 ? _len6 - 1 : 0), _key6 = 1; _key6 < _len6; _key6++) {
        args[_key6 - 1] = arguments[_key6];
      }

      var groups = args[0],
          consentGroups = args[1],
          tcf = args[2];
      setContextValue(function (state) {
        var additionalChanges = {
          // In case we previously used non-TCF functionality, do not show the TCF banner
          isTcf: !!tcf,
          tcf: null
        };

        if (process.env.IS_TCF === "1" && tcf) {
          Object.assign(additionalChanges, {
            tcf: "gvl" in tcf ? tcf : createTcfModel(tcf)
          });
        }

        return _objectSpread(_objectSpread({}, state), {}, {
          groups: groups,
          consent: _objectSpread(_objectSpread({}, state.consent), {}, {
            groups: consentGroups
          })
        }, additionalChanges);
      });
    }
  };
}

export { useBannerStateContextCallbacks };