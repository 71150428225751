import { useBannerOrBlocker, useLegalLinks } from "..";

function joinWithAndSeparator(arr, andSeparator) {
  if (arr.length > 1) {
    arr.splice(arr.length - 1, 0, "{{andSeparator}}");
  }

  return arr.join(", ").replace(/,\s+{{andSeparator}},\s+/g, andSeparator);
}
/**
 * @see https://gist.github.com/unruthless/413930
 * @see https://stackoverflow.com/a/23630325/5506547
 */


var supWithLineHeightFix = '<sup style="vertical-align:top;line-height:100%;position:initial;">';

function useCommonTeachings() {
  var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
      disableDataProcessingInUnsafeCountries = _ref.disableDataProcessingInUnsafeCountries,
      disableAgeNotice = _ref.disableAgeNotice,
      disableListServicesNotice = _ref.disableListServicesNotice;

  var _useBannerOrBlocker = useBannerOrBlocker(),
      blocker = _useBannerOrBlocker.blocker,
      groups = _useBannerOrBlocker.groups,
      isEPrivacyUSA = _useBannerOrBlocker.ePrivacyUSA,
      isAgeNotice = _useBannerOrBlocker.ageNotice,
      isListServicesNotice = _useBannerOrBlocker.listServicesNotice,
      _useBannerOrBlocker$t = _useBannerOrBlocker.texts,
      description = _useBannerOrBlocker$t.description,
      ePrivacyUSA = _useBannerOrBlocker$t.ePrivacyUSA,
      ageNoticeBanner = _useBannerOrBlocker$t.ageNoticeBanner,
      listServicesNotice = _useBannerOrBlocker$t.listServicesNotice,
      consentForwardingExternalHostsText = _useBannerOrBlocker$t.consentForwardingExternalHosts,
      tcf = _useBannerOrBlocker.tcf,
      consentForwardingExternalHosts = _useBannerOrBlocker.consentForwardingExternalHosts,
      individualPrivacyOpen = _useBannerOrBlocker.individualPrivacyOpen,
      individualDescription = _useBannerOrBlocker.individualTexts.description,
      essentialGroup = _useBannerOrBlocker.essentialGroup,
      _useBannerOrBlocker$i = _useBannerOrBlocker.i18n,
      andSeparator = _useBannerOrBlocker$i.andSeparator,
      tcfBannerI18n = _useBannerOrBlocker$i.tcf,
      keepVariablesInTexts = _useBannerOrBlocker.keepVariablesInTexts;

  var _useLegalLinks = useLegalLinks(),
      linkPrivacyPolicy = _useLegalLinks.linkPrivacyPolicy; // Description text (combination of configured text and Consent Forwarding)


  var consentForwardingExternalHostsFinalText = "";

  if (consentForwardingExternalHosts && !keepVariablesInTexts) {
    consentForwardingExternalHostsFinalText = consentForwardingExternalHostsText.replace(/{{websites}}/g, consentForwardingExternalHosts.join(", "));
  }

  var useDescription = [blocker ? blocker.description : individualPrivacyOpen ? individualDescription : description, consentForwardingExternalHostsFinalText].filter(Boolean).join(" ");

  if (!keepVariablesInTexts) {
    // Place policy link into the text https://regex101.com/r/ayeFVy/1
    useDescription = useDescription.replace(/{{privacyPolicy}}(.*){{\/privacyPolicy}}/g, linkPrivacyPolicy ? "<a href=\"".concat(linkPrivacyPolicy.url, "\" style=\"text-decoration:underline;color:inherit;\" onmouseenter=\"this.style.textDecoration='none'\" onmouseleave=\"this.style.textDecoration='underline'\" target=\"_blank\">$1</a>") : "$1");
  } // Teaching: Data processing in unsafe countries (formerly USA ePrivacy)


  var useDataProcessingInUnsafeCountries = !disableDataProcessingInUnsafeCountries && isEPrivacyUSA ? ePrivacyUSA : ""; // Teaching: Age notice

  var useAgeNotice = isAgeNotice && !disableAgeNotice ? ageNoticeBanner : ""; // Teaching: List services notice

  var useListServicesNotice = "";

  if (isListServicesNotice && !disableListServicesNotice) {
    var hasBothPlaceholders = listServicesNotice.indexOf("{{services}}") > -1 && listServicesNotice.indexOf("{{serviceGroups}}") > -1;
    var nonEssentialGroups = groups.filter(function (_ref2) {
      var slug = _ref2.slug;
      return slug !== essentialGroup;
    });
    var flattenSeparator = "~;~";
    var listServicesNames = joinWithAndSeparator(nonEssentialGroups.map(function (_ref3, groupIdx) {
      var items = _ref3.items;
      return items.map(function (_ref4) {
        var name = _ref4.name,
            ePrivacyUSA = _ref4.ePrivacyUSA;
        return "".concat(name).concat(supWithLineHeightFix).concat([hasBothPlaceholders ? groupIdx + 1 : "", useDataProcessingInUnsafeCountries && ePrivacyUSA ? "U" : ""].filter(Boolean).join(","), "</sup>");
      }).join(flattenSeparator);
    }).join(flattenSeparator).split(flattenSeparator), andSeparator);

    if (listServicesNames) {
      var listServiceGroupsNames = joinWithAndSeparator(nonEssentialGroups.map(function (_ref5, groupIdx) {
        var name = _ref5.name;
        return "".concat(name).concat(supWithLineHeightFix).concat(hasBothPlaceholders ? groupIdx + 1 : "", "</sup>");
      }), andSeparator);
      useListServicesNotice = "<span>".concat(listServicesNotice, "</span>").concat(tcf && !individualPrivacyOpen ? "<span>".concat(tcfBannerI18n.listOfServicesAppendix, "</span>") : "");

      if (!keepVariablesInTexts) {
        // Place service list into the text
        useListServicesNotice = useListServicesNotice.replace(/{{services}}/g, listServicesNames) // Place service group list into the text
        .replace(/{{serviceGroups}}/g, listServiceGroupsNames);
      }

      if (useDataProcessingInUnsafeCountries) {
        useDataProcessingInUnsafeCountries += "".concat(supWithLineHeightFix, "U</sup>");
      }
    }
  }

  return {
    description: useDescription,
    teachings: [useDataProcessingInUnsafeCountries, useAgeNotice, useListServicesNotice].filter(Boolean)
  };
}

export { useCommonTeachings };