import { Fragment, useMemo } from "react";
import { useBanner, useBannerButtonCallbacks, BannerButton } from "../../..";

var BannerButtonList = function BannerButtonList() {
  var banner = useBanner();
  var activeAction = banner.activeAction,
      _banner$bodyDesign = banner.bodyDesign,
      acceptEssentialsUseAcceptAll = _banner$bodyDesign.acceptEssentialsUseAcceptAll,
      acceptAllOneRowLayout = _banner$bodyDesign.acceptAllOneRowLayout,
      _banner$decision = banner.decision,
      showGroups = _banner$decision.showGroups,
      groupsFirstView = _banner$decision.groupsFirstView,
      saveButton = _banner$decision.saveButton,
      acceptAll = _banner$decision.acceptAll,
      acceptEssentials = _banner$decision.acceptEssentials,
      acceptIndividual = _banner$decision.acceptIndividual,
      buttonOrderString = _banner$decision.buttonOrder,
      _banner$texts = banner.texts,
      acceptAllText = _banner$texts.acceptAll,
      acceptEssentialsText = _banner$texts.acceptEssentials,
      acceptIndividualText = _banner$texts.acceptIndividual,
      _banner$saveButton = banner.saveButton,
      saveButtonType = _banner$saveButton.type,
      saveButtonUseAcceptAll = _banner$saveButton.useAcceptAll,
      save = banner.individualTexts.save,
      individualPrivacyOpen = banner.individualPrivacyOpen,
      didGroupFirstChange = banner.didGroupFirstChange,
      productionNotice = banner.productionNotice,
      _banner$buttonClicked = banner.buttonClicked,
      buttonClicked = _banner$buttonClicked === void 0 ? "" : _banner$buttonClicked; // Calculate order of the buttons depending on a string like `all,essential,save,individual`

  var _useMemo = useMemo(function () {
    var buttonOrder = buttonOrderString.split(",");
    var buttonToOrder = buttonOrder.reduce(function (previous, curr) {
      previous[curr] = buttonOrder.indexOf(curr);
      return previous;
    }, {});
    var orderToButton = buttonOrder.reduce(function (previous, curr) {
      previous[buttonOrder.indexOf(curr)] = curr;
      return previous;
    }, {}); // If one-row layout is enabled, reverse the first two orders so the first button gets moved to the right

    // If one-row layout is enabled, reverse the first two orders so the first button gets moved to the right
    if (acceptAllOneRowLayout) {
      buttonToOrder[orderToButton[0]] = 1;
      buttonToOrder[orderToButton[1]] = 0;
    }

    return buttonToOrder;
  }, [buttonOrderString, acceptAllOneRowLayout]),
      buttonOrderAll = _useMemo.all,
      buttonOrderEssential = _useMemo.essential,
      buttonOrderIndividual = _useMemo.individual,
      buttonOrderSave = _useMemo.save;

  var isPro = process.env.PLUGIN_CTX === "pro";

  var _useBannerButtonCallb = useBannerButtonCallbacks(),
      buttonClickedAll = _useBannerButtonCallb.buttonClickedAll,
      buttonClickedEssentials = _useBannerButtonCallb.buttonClickedEssentials,
      buttonClickedCustom = _useBannerButtonCallb.buttonClickedCustom,
      handleAll = _useBannerButtonCallb.acceptAll,
      handleEssentials = _useBannerButtonCallb.acceptEssentials,
      handleIndividual = _useBannerButtonCallb.acceptIndividual,
      openIndividualPrivacy = _useBannerButtonCallb.openIndividualPrivacy; // We expect a reload of the page for the "change" action (keeps also dialog open)


  var busyOnClick = activeAction === "change";
  var groupsFirstViewEnabled = showGroups && groupsFirstView;
  return h(Fragment, null, !individualPrivacyOpen && isPro && groupsFirstViewEnabled && saveButton === "afterChangeAll" && didGroupFirstChange ? h(BannerButton, {
    onClick: handleIndividual,
    type: acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick,
    order: buttonOrderAll
  }, save) : h(BannerButton, {
    onClick: handleAll,
    type: acceptAll,
    inlineStyle: "acceptAll",
    framed: buttonClicked === buttonClickedAll,
    busyOnClick: busyOnClick,
    order: buttonOrderAll
  }, acceptAllText), activeAction !== "change" && h(BannerButton, {
    onClick: handleEssentials,
    type: acceptEssentialsUseAcceptAll ? acceptAll : acceptEssentials,
    inlineStyle: acceptEssentialsUseAcceptAll ? "acceptAll" : "acceptEssentials",
    framed: buttonClicked === buttonClickedEssentials,
    busyOnClick: busyOnClick,
    order: buttonOrderEssential
  }, acceptEssentialsText), (individualPrivacyOpen || isPro && groupsFirstViewEnabled && (saveButton === "always" || saveButton === "afterChange" && didGroupFirstChange)) && h(BannerButton, {
    onClick: handleIndividual,
    type: saveButtonUseAcceptAll ? acceptAll : saveButtonType,
    inlineStyle: saveButtonUseAcceptAll ? "acceptAll" : "save",
    framed: buttonClicked === buttonClickedCustom,
    busyOnClick: busyOnClick,
    order: buttonOrderSave
  }, save), !individualPrivacyOpen && h(BannerButton, {
    type: acceptIndividual,
    onClick: openIndividualPrivacy,
    inlineStyle: "acceptIndividual",
    framed: buttonClicked.startsWith("ind_"),
    busyOnClick: busyOnClick,
    order: buttonOrderIndividual
  }, acceptIndividualText), productionNotice);
};

export { BannerButtonList };