import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { useCallback } from "react";
import { useBannerAnimation } from "../..";
/**
 * Provides a callback which can be used to update the state of the banner context and manipulate
 * the `visible` and `animationVisible` property correctly.
 */

function useBannerToggleOverlay(overlay) {
  var useBannerAnimationCallback = useBannerAnimation();
  var toggleOverlay = useCallback(function (_ref, show) {
    var layout = _ref.layout;
    var animationInDuration = layout.animationInDuration,
        animationOutDuration = layout.animationOutDuration;

    var _useBannerAnimationCa = useBannerAnimationCallback(layout),
        useAnimationIn = _useBannerAnimationCa.useAnimationIn,
        useAnimationOut = _useBannerAnimationCa.useAnimationOut;

    var defaultIfNoAnimation = 0; // Avoid "hard" overlay

    var useDuration = show ? useAnimationIn === "none" ? defaultIfNoAnimation : animationInDuration : useAnimationOut === "none" ? defaultIfNoAnimation : animationOutDuration; // Toggle SSR-rendered overlay

    useDuration > 0 && (overlay.style.transition = "background ".concat(useDuration, "ms"));
    overlay.style.background = "transparent";

    if (show) {
      // We need a small delay to avoid flickering of animation out effect in customizer
      setTimeout(function () {
        overlay.style.display = "block";
      }, 0);
      setTimeout(function () {
        var bg = overlay.getAttribute("data-bg");
        overlay.style.background = bg ? bg.split(":")[1].trim().replace(";", "") : "transparent";
      }, 100);
    } else {
      setTimeout(function () {
        overlay.style.display = "none";
      }, useDuration);
    }

    var animationVisible = {
      animationVisible: show
    };
    return show ? _objectSpread({
      visible: true
    }, animationVisible) : animationVisible;
  }, [useBannerAnimationCallback]);
  return {
    overlay: overlay,
    toggleOverlay: toggleOverlay
  };
}

export { useBannerToggleOverlay };