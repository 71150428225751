import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import { useState } from "react";
import { commonInlineStyleFooterLink, useBannerOrBlocker } from "../../..";

var FooterLink = function FooterLink(_ref) {
  var children = _ref.children,
      anchorRest = _objectWithoutProperties(_ref, ["children"]);

  var bannerOrBlocker = useBannerOrBlocker();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isHover = _useState2[0],
      setIsHover = _useState2[1];

  return h("a", _extends({
    onMouseEnter: function onMouseEnter() {
      return setIsHover(true);
    },
    onMouseLeave: function onMouseLeave() {
      return setIsHover(false);
    }
  }, commonInlineStyleFooterLink(bannerOrBlocker, isHover), anchorRest), children);
};

export { FooterLink };