import _extends from "@babel/runtime/helpers/extends";
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { isUrl } from "@devowl-wp/headless-content-unblocker";
import { useBannerOrBlocker } from "../../../hooks";
import { commonGroupsInlineStyleLinkMore, commonInlineStyleGroupsCookieProperty } from "../../../inlineStyles";

var CookieProperty = function CookieProperty(_ref) {
  var label = _ref.label,
      value = _ref.value,
      children = _ref.children,
      printValueAs = _ref.printValueAs;
  var banner = useBannerOrBlocker();
  var _banner$i18n = banner.i18n,
      yes = _banner$i18n.yes,
      no = _banner$i18n.no;
  var isValueUrl = typeof value === "string" && isUrl(value);
  var useValue = isValueUrl ? h("a", {
    href: value,
    style: _objectSpread({
      wordBreak: "break-all"
    }, commonGroupsInlineStyleLinkMore(banner, false).style),
    target: "_blank",
    rel: "noopener noreferrer"
  }, value) : typeof value === "string" ? h("span", {
    dangerouslySetInnerHTML: {
      __html: value
    }
  }) : value;

  if (printValueAs === "boolean") {
    useValue = useValue ? yes : no;
  }

  return h("div", _extends({
    key: label
  }, commonInlineStyleGroupsCookieProperty(banner)), label && h("strong", null, label, ":\xA0"), useValue, !!children && h("div", null, children));
};

export { CookieProperty };