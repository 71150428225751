import { bannerButtonsInlineStyleCommon } from ".";

function bannerButtonsInlineStyleAcceptAll(_ref, isHover, framed, order, isTwoButtonRowLayout) {
  var acceptAll = _ref.decision.acceptAll,
      borderRadius = _ref.layout.borderRadius,
      _ref$bodyDesign = _ref.bodyDesign,
      acceptAllFontSize = _ref$bodyDesign.acceptAllFontSize,
      acceptAllBg = _ref$bodyDesign.acceptAllBg,
      acceptAllTextAlign = _ref$bodyDesign.acceptAllTextAlign,
      acceptAllBorderColor = _ref$bodyDesign.acceptAllBorderColor,
      acceptAllPadding = _ref$bodyDesign.acceptAllPadding,
      acceptAllBorderWidth = _ref$bodyDesign.acceptAllBorderWidth,
      acceptAllFontColor = _ref$bodyDesign.acceptAllFontColor,
      acceptAllFontWeight = _ref$bodyDesign.acceptAllFontWeight,
      acceptAllHoverBg = _ref$bodyDesign.acceptAllHoverBg,
      acceptAllHoverFontColor = _ref$bodyDesign.acceptAllHoverFontColor,
      acceptAllHoverBorderColor = _ref$bodyDesign.acceptAllHoverBorderColor,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  return bannerButtonsInlineStyleCommon({
    name: "accept-all",
    order: order,
    type: acceptAll,
    borderRadius: borderRadius,
    bg: acceptAllBg,
    hoverBg: acceptAllHoverBg,
    fontSize: acceptAllFontSize,
    textAlign: acceptAllTextAlign,
    fontColor: acceptAllFontColor,
    fontWeight: acceptAllFontWeight,
    hoverFontColor: acceptAllHoverFontColor,
    borderWidth: acceptAllBorderWidth,
    borderColor: acceptAllBorderColor,
    hoverBorderColor: acceptAllHoverBorderColor,
    padding: acceptAllPadding,
    antiAdBlocker: antiAdBlocker,
    pageRequestUuid4: pageRequestUuid4
  }, mobile, isHover, framed, isTwoButtonRowLayout);
}

export { bannerButtonsInlineStyleAcceptAll };