import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useLayoutEffect, Fragment, useCallback } from "react"; // eslint-disable-next-line import/no-extraneous-dependencies

import { CheckOutlined } from "@ant-design/icons-svg";
import { useBanner } from "../../../contexts";
import { bannerInlineStyleBodyDottedGroupItem, commonInlineStyleGroupsCheckbox, bannerInlineStyleBodyDottedGroupBullet } from "../../../inlineStyles";
import { PlainAntdIconAsSvg } from "../..";

var BannerDottedGroup = function BannerDottedGroup(_ref) {
  var _ref$group = _ref.group,
      id = _ref$group.id,
      slug = _ref$group.slug,
      name = _ref$group.name;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isRendered = _useState2[0],
      setIsRendered = _useState2[1];

  var banner = useBanner();
  var groupsFirstView = banner.decision.groupsFirstView,
      fontSize = banner.design.fontSize,
      consent = banner.consent,
      essentialGroup = banner.essentialGroup;
  var isPro = process.env.PLUGIN_CTX === "pro";
  useLayoutEffect(function () {
    setIsRendered(true);
  }, []);
  var isEssentialGroup = essentialGroup === slug;
  var isChecked = isEssentialGroup || !!consent.groups[id];
  var handleCheckbox = useCallback(function () {
    return isPro && groupsFirstView && !isEssentialGroup && banner.updateGroupChecked(id, !isChecked);
  }, [isPro, isEssentialGroup, banner, id, isChecked, groupsFirstView]);
  return h("span", _extends({
    key: name
  }, bannerInlineStyleBodyDottedGroupItem(banner)), h("span", null, isPro && groupsFirstView ? h(PlainAntdIconAsSvg, _extends({
    icon: CheckOutlined,
    onClick: handleCheckbox
  }, commonInlineStyleGroupsCheckbox(banner, isRendered, isChecked, isEssentialGroup, fontSize))) : h(Fragment, null, h("strong", bannerInlineStyleBodyDottedGroupBullet(banner), "\u25CF"), "\xA0"), h("span", {
    style: {
      verticalAlign: "middle",
      cursor: groupsFirstView ? "pointer" : undefined
    },
    onClick: handleCheckbox
  }, name)));
};

export { BannerDottedGroup };