import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";

/**
 * Get the default decision and respect essential group and legitimate interests.
 */
function getDefaultDecision(_ref) {
  var groups = _ref.groups,
      essentialGroupSlug = _ref.essentialGroupSlug;
  var respectLegitimateInterests = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  var _groups$filter = groups.filter(function (_ref2) {
    var slug = _ref2.slug;
    return slug === essentialGroupSlug;
  }),
      _groups$filter2 = _slicedToArray(_groups$filter, 1),
      essentialGroupObj = _groups$filter2[0]; // We need always use the current essential group


  var result = _defineProperty({}, essentialGroupObj.id, essentialGroupObj.items.map(function (_ref3) {
    var id = _ref3.id;
    return id;
  }));

  if (respectLegitimateInterests) {
    var _iterator = _createForOfIteratorHelper(groups),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var group = _step.value;

        if (group === essentialGroupObj) {
          continue;
        }

        var ids = group.items.filter(function (_ref4) {
          var legalBasis = _ref4.legalBasis;
          return legalBasis === "legitimate-interest";
        }).map(function (_ref5) {
          var id = _ref5.id;
          return id;
        });

        if (ids.length) {
          result[group.id] = ids;
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  }

  return result;
}

export { getDefaultDecision };