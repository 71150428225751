import { getOtherOptionsFromWindow } from "../utils";

var BannerProductionNotice = function BannerProductionNotice() {
  var _getOtherOptionsFromW = getOtherOptionsFromWindow(),
      isPro = _getOtherOptionsFromW.isPro,
      isLicensed = _getOtherOptionsFromW.isLicensed,
      isDevLicense = _getOtherOptionsFromW.isDevLicense,
      _getOtherOptionsFromW2 = _getOtherOptionsFromW.bannerI18n,
      noLicense = _getOtherOptionsFromW2.noLicense,
      devLicense = _getOtherOptionsFromW2.devLicense;

  if (!isPro) {
    return null;
  }

  var useText = "";

  if (isDevLicense) {
    useText = devLicense;
  } else if (!isLicensed) {
    useText = noLicense;
  }

  return useText ? h("div", {
    style: {
      fontSize: 13,
      color: "#c95252",
      fontWeight: "bold",
      order: 9
    }
  }, useText) : null;
};

export { BannerProductionNotice };