import { scaleMobile } from "../..";

function bannerGroupsInlineStyleGroup(_ref) {
  var _ref$group = _ref.group,
      groupBg = _ref$group.groupBg,
      groupBorderRadius = _ref$group.groupBorderRadius,
      groupBorderColor = _ref$group.groupBorderColor,
      groupPadding = _ref$group.groupPadding,
      groupBorderWidth = _ref$group.groupBorderWidth,
      groupInheritBg = _ref$group.groupInheritBg,
      antiAdBlocker = _ref.customCss.antiAdBlocker,
      mobile = _ref.mobile,
      pageRequestUuid4 = _ref.pageRequestUuid4;
  var useGroupBorderWidth = scaleMobile(mobile, pageRequestUuid4, groupBorderWidth);
  return {
    className: antiAdBlocker === "y" ? undefined : "rcb-group",
    style: {
      background: groupInheritBg ? undefined : groupBg,
      border: useGroupBorderWidth > 0 ? "".concat(useGroupBorderWidth, "px solid ").concat(groupBorderColor) : undefined,
      padding: scaleMobile(mobile, pageRequestUuid4, groupPadding).map(function (p) {
        return "".concat(p, "px");
      }).join(" "),
      borderRadius: +groupBorderRadius,
      textAlign: "left"
    }
  };
}

export { bannerGroupsInlineStyleGroup };