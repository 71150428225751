import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";

/**
 * Get cookie by name. Please keep in mind you need to `decodeURIComponent` the value in some cases!
 *
 * @param cname
 * @see https://stackoverflow.com/questions/10730362/get-cookie-by-name
 * @see https://stackoverflow.com/a/25490531/5506547
 */
function getCookie(cname) {
  var decode = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

  // In some cases there could be cookies with the same name in `document.cooke` -> take the last one
  var matches = _toConsumableArray(document.cookie.matchAll(new RegExp("(^|;)\\s*".concat(cname, "\\s*=\\s*([^;]+)"), "g")));

  if (!matches.length) {
    return "";
  }

  var value = matches // Last match in `document.cookie`
  .pop() // Value of regex match
  .pop();
  return decode ? decodeURIComponent(value) : value;
}

export { getCookie };