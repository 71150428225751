import { hexToRgb } from ".";

/**
 * @param hex
 * @param alpha 0 - 100
 */
function calculateRgba(hex, alpha) {
  var _hexToRgb = hexToRgb(hex),
      r = _hexToRgb.r,
      g = _hexToRgb.g,
      b = _hexToRgb.b;

  return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(alpha / 100, ")");
}

export { calculateRgba };