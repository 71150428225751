var DYNAMICS_VARIABLE_REGEXP = /{{([A-Za-z0-9_]+)}}/gm;
/**
 * Takes any string and replaces `{{myVariable}}` with the value of the passed `dynamics` map.
 */

function applyDynamicsToHtml(src, dynamics) {
  return src.replace(DYNAMICS_VARIABLE_REGEXP, function (fullMatch, variableName) {
    return Object.prototype.hasOwnProperty.call(dynamics, variableName) ? dynamics[variableName] : fullMatch;
  });
}

export { applyDynamicsToHtml, DYNAMICS_VARIABLE_REGEXP };