import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { getUserDecision, OPT_IN_ALL_EVENT } from "@devowl-wp/cookie-consent-web-client";
import { useEffect } from "react";

function useBannerConsentListener(setContextValue, userConsentCookieName) {
  useEffect(function () {
    var listener = function listener() {
      var consent = getUserDecision(userConsentCookieName);
      consent && setContextValue(function (state) {
        return _objectSpread(_objectSpread({}, state), {}, {
          consent: {
            groups: consent.consent
          }
        });
      });
    };

    document.addEventListener(OPT_IN_ALL_EVENT, listener);
    return function () {
      document.removeEventListener(OPT_IN_ALL_EVENT, listener);
    };
  }, []);
}

export { useBannerConsentListener };