import _extends from "@babel/runtime/helpers/extends";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useBanner, bannerInlineStyleHistorySelect, bannerInlineStyleHistorySelectOption } from "../../..";
import { useMemo } from "react";
import { Fragment, useState, useEffect } from "react";
var SELECT_NONE = -1;

var BannerHistorySelect = function BannerHistorySelect() {
  var banner = useBanner();

  var _useState = useState(SELECT_NONE),
      _useState2 = _slicedToArray(_useState, 2),
      selectedId = _useState2[0],
      setSelectedId = _useState2[1];

  var activeAction = banner.activeAction,
      history = banner.history,
      selectHistoryEntry = banner.selectHistoryEntry,
      _banner$i18n = banner.i18n,
      historyLabel = _banner$i18n.historyLabel,
      historySelectNone = _banner$i18n.historySelectNone;
  var selectedHistoryItem = useMemo(function () {
    var _history$filter;

    return history === null || history === void 0 ? void 0 : (_history$filter = history.filter(function (_ref) {
      var id = _ref.id;
      return id === selectedId;
    })) === null || _history$filter === void 0 ? void 0 : _history$filter[0];
  }, [selectedId]); // Reset select after exit history dialog

  useEffect(function () {
    if (!activeAction) {
      setSelectedId(SELECT_NONE);
    } else if (activeAction === "history" && history !== null && history !== void 0 && history.length) {
      setSelectedId(history[0].id);
    }
  }, [activeAction, history]); // History can only contain my data, so we can pick the first entry for the history

  var uuid = selectedHistoryItem === null || selectedHistoryItem === void 0 ? void 0 : selectedHistoryItem.uuid;
  var optionStyle = bannerInlineStyleHistorySelectOption(banner);
  return h(Fragment, null, historyLabel, "\xA0", h("select", _extends({}, bannerInlineStyleHistorySelect(banner), {
    disabled: !(history !== null && history !== void 0 && history.length),
    value: selectedId,
    onChange: function onChange(e) {
      var value = +e.target.value;
      history.forEach(function (_ref2) {
        var id = _ref2.id,
            decision = _ref2.decision,
            groups = _ref2.groups,
            tcf = _ref2.tcf;

        if (id === value) {
          setSelectedId(id);
          selectHistoryEntry(groups, decision, tcf);
        }
      });
    }
  }), (history === null || history === void 0 ? void 0 : history.length) > 0 ? history.map(function (_ref3) {
    var id = _ref3.id,
        isDoNotTrack = _ref3.isDoNotTrack,
        isUnblock = _ref3.isUnblock,
        isForwarded = _ref3.isForwarded,
        created = _ref3.created;
    return h("option", _extends({
      key: id,
      value: id
    }, optionStyle), new Date(created).toLocaleString(document.documentElement.lang), isDoNotTrack ? " (Do Not Track)" : "", isUnblock ? " (Content Blocker)" : "", isForwarded ? " (Consent Forwarding)" : "");
  }) : h("option", _extends({
    value: SELECT_NONE
  }, optionStyle), historySelectNone)), !!uuid && h("div", {
    style: {
      opacity: 0.5,
      marginTop: 5
    }
  }, "UUID: ", uuid));
};

export { BannerHistorySelect };