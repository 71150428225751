import { Fragment } from "react";
import { CookieProperty } from ".";
import { useBannerOrBlocker } from "../../../hooks";
import { applyDynamicsToHtml } from "@devowl-wp/headless-content-unblocker";

var CookiePropertyList = function CookiePropertyList(_ref) {
  var _ref$cookie = _ref.cookie,
      purpose = _ref$cookie.purpose,
      provider = _ref$cookie.provider,
      providerPrivacyPolicy = _ref$cookie.providerPrivacyPolicy,
      legalBasis = _ref$cookie.legalBasis,
      ePrivacyUSA = _ref$cookie.ePrivacyUSA,
      noTechnicalDefinitions = _ref$cookie.noTechnicalDefinitions,
      technicalDefinitions = _ref$cookie.technicalDefinitions,
      codeDynamics = _ref$cookie.codeDynamics,
      isEssentialGroup = _ref.isEssentialGroup;

  var _useBannerOrBlocker = useBannerOrBlocker(),
      i18n = _useBannerOrBlocker.i18n,
      ePrivacyUSAEnabled = _useBannerOrBlocker.ePrivacyUSA;

  var types = getCookieTypeLocalizationMap();
  return h(Fragment, null, !!purpose && h(CookieProperty, {
    label: i18n.purpose,
    value: purpose
  }), h(CookieProperty, {
    label: i18n.legalBasis,
    value: legalBasis === "legal-requirement" ? i18n.legalRequirement : legalBasis === "legitimate-interest" || isEssentialGroup ? i18n.legitimateInterest : i18n.consent
  }), h(CookieProperty, {
    label: i18n.provider,
    value: provider
  }), !!providerPrivacyPolicy && h(CookieProperty, {
    label: i18n.providerPrivacyPolicy,
    value: providerPrivacyPolicy
  }), !!ePrivacyUSAEnabled && h(CookieProperty, {
    label: i18n.ePrivacyUSA,
    value: ePrivacyUSA,
    printValueAs: "boolean"
  }), !noTechnicalDefinitions && technicalDefinitions.map(function (_ref2) {
    var type = _ref2.type,
        name = _ref2.name,
        host = _ref2.host,
        duration = _ref2.duration,
        durationUnit = _ref2.durationUnit,
        sessionDuration = _ref2.sessionDuration,
        purpose = _ref2.purpose;
    return h(CookieProperty, {
      key: name,
      label: i18n.technicalCookieDefinition,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, applyDynamicsToHtml(name, codeDynamics))
    }, h(CookieProperty, {
      label: i18n.type,
      value: types[type].name
    }), !!purpose && h(CookieProperty, {
      label: i18n.purpose,
      value: purpose
    }), !!host && h(CookieProperty, {
      label: i18n.host,
      value: h("span", {
        style: {
          fontFamily: "monospace"
        }
      }, host)
    }), h(CookieProperty, {
      label: i18n.duration,
      value: ["local", "indexedDb", "flash"].indexOf(type) > -1 ? i18n.noExpiration : sessionDuration || type === "session" ? "Session" : "".concat(duration, " ").concat(i18n.durationUnit[durationUnit])
    }));
  }));
};

function getCookieTypeLocalizationMap() {
  return {
    http: {
      name: "HTTP Cookie",
      abbr: "HTTP",
      backgroundColor: "black"
    },
    local: {
      name: "Local Storage",
      abbr: "Local",
      backgroundColor: "#b3983c"
    },
    session: {
      name: "Session Storage",
      abbr: "Session",
      backgroundColor: "#3c99b3"
    },
    flash: {
      name: "Flash Local Shared Object",
      abbr: "Flash",
      backgroundColor: "#b33c3c"
    },
    indexedDb: {
      name: "IndexedDB",
      abbr: "I-DB",
      backgroundColor: "#4ab33c"
    }
  };
}

export { CookiePropertyList, getCookieTypeLocalizationMap };